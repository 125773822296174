export async function getMenuDataPT() {
  return [
    {
      title: 'Usuários',
      key: 'users',
      icon: 'fe fe-users',
      url: '/database/users',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      title: 'Tipos',
      key: 'tipos',
      icon: 'fe fe-home',
      url: '/database/tipos',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      title: 'Checkins',
      key: 'checkins',
      icon: 'fe fe-calendar',
      url: '/database/checkins',
      // roles: ['admin'], // set user roles with access to this route
    },

  ]
}



export async function getMenuDataJP() {
  return [
    {
      category: true,
      title: 'スケジュール',
    },
    {
      title: 'カレンダー',
      key: 'calendar',
      icon: 'fe fe-calendar',
      url: '/schedule/calendar',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      title: '通知',
      key: 'notifications',
      icon: 'fe fe-bell',
      url: '/schedule/notifications',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      category: true,
      title: '登録',
    },
    {
      title: '利用者',
      key: 'children',
      icon: 'fe fe-heart',
      url: '/database/children',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      title: '指導員',
      key: 'specialists',
      icon: 'fe fe-user-check',
      url: '/database/specialists',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      category: true,
      title: 'コミュニケーション',
    },
    {
      title: 'メッセージ',
      key: 'messages',
      icon: 'fe fe-mail',
      url: '/schedule/messages',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      category: true,
      title: '設定',
    },
    {
      title: '校舎',
      key: 'units',
      icon: 'fe fe-home',
      url: '/database/units',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      title: '診断名',
      key: 'syndromes',
      icon: 'fe fe-feather',
      url: '/database/syndromes',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      title: '専門等',
      key: 'professions',
      icon: 'fe fe-thumbs-up',
      url: '/database/professions',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      title: 'ユーザー',
      key: 'users',
      icon: 'fe fe-users',
      url: '/database/users',
      // roles: ['admin'], // set user roles with access to this route
    },


  ]
}


export async function getMenuDataEN() {
  return [
    {
      category: true,
      title: 'スケジュール',
    },
    {
      title: 'カレンダー',
      key: 'calendar',
      icon: 'fe fe-calendar',
      url: '/schedule/calendar',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      title: '通知',
      key: 'notifications',
      icon: 'fe fe-bell',
      url: '/schedule/notifications',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      category: true,
      title: '登録',
    },
    {
      title: 'キッズ',
      key: 'children',
      icon: 'fe fe-heart',
      url: '/database/children',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      title: 'セラピスト',
      key: 'specialists',
      icon: 'fe fe-user-check',
      url: '/database/specialists',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      category: true,
      title: 'コミュニケーション',
    },
    {
      title: 'メッセージ',
      key: 'messages',
      icon: 'fe fe-mail',
      url: '/schedule/messages',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      category: true,
      title: '設定',
    },
    {
      title: '単位',
      key: 'units',
      icon: 'fe fe-home',
      url: '/database/units',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      title: '症候群',
      key: 'syndromes',
      icon: 'fe fe-feather',
      url: '/database/syndromes',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      title: '特産品',
      key: 'professions',
      icon: 'fe fe-thumbs-up',
      url: '/database/professions',
      // roles: ['admin'], // set user roles with access to this route
    },
    {
      title: 'ユーザー',
      key: 'users',
      icon: 'fe fe-users',
      url: '/database/users',
      // roles: ['admin'], // set user roles with access to this route
    },


  ]
}