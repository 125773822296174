import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import * as api from 'services/api'
import { history } from 'index'
import actions from './actions'

export function* LIST() {
  yield put({
    type: 'checkins/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(api.checkinsList)
  if (success) {
    yield put({
      type: 'checkins/SET_STATE',
      payload: {
        list: success,
        loading: false,
      }
    })
  }
  if (!success) {
    yield put({
      type: 'checkins/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* DETAILS({ payload }) {
  const { id } = payload
  yield put({
    type: 'checkins/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(api.checkinDetails, id)
  if (success) {
    yield put({
      type: 'checkins/SET_STATE',
      payload: {
        selected: success,
        loading: false,
      }
    })
  }
  if (!success) {
    yield put({
      type: 'checkins/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { id, checkinData } = payload
  yield put({
    type: 'checkins/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(api.updateCheckin, id, checkinData)
  if (success) {
    yield put({
      type: 'checkins/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield history.push('/database/checkins')
    notification.success({
      message: 'Success',
      description: 'Data saved!',
    })
  }
  if (!success) {
    yield put({
      type: 'checkins/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* CREATE({ payload }) {
  const { checkinData } = payload
  yield put({
    type: 'checkins/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(api.createCheckin, checkinData)
  if (success) {
    yield put({
      type: 'checkins/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield history.push('/database/checkins')
    notification.success({
      message: 'Success',
      description: 'Data saved!',
    })
  }
  if (!success) {
    yield put({
      type: 'checkins/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.DETAILS, DETAILS),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.CREATE, CREATE),
  ])
}
