import { apiClient } from 'services/axios'

export async function usersList(userUnit) {
  return apiClient
    .get('/users', {
        params: {
          unit_eq: userUnit ? userUnit.id : null,
          _limit: 2000
        }
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function userDetails(id) {
  return apiClient
    .get(`/users/${id}`, {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateUser(id, userData) {
  return apiClient
    .put(`/users/${id}`, {
      ...userData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createUser(userData) {
  return apiClient
    .post('/users', {
      ...userData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function tiposList() {
  return apiClient
    .get('/tipo-usuarios', {
      params: {
        _limit: 2000
      }
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function tipoDetails(id) {
  return apiClient
    .get(`/tipo-usuarios/${id}`, {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateTipo(id, tipoData) {
  return apiClient
    .put(`/tipo-usuarios/${id}`, {
      ...tipoData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createTipo(tipoData) {
  return apiClient
    .post('/tipo-usuarios', {
      ...tipoData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function checkinsList() {
  return apiClient
    .get('/checkins', {
      params: {
        _sort: 'login:desc'
      }
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function checkinDetails(id) {
  return apiClient
    .get(`/checkins/${id}`, {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateCheckin(id, checkinData) {
  return apiClient
    .put(`/groups/${id}`, {
      ...checkinData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createCheckin(checkinData) {
  return apiClient
    .post('/checkins', {
      ...checkinData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
