import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import users from './users/sagas'
import tipos from './tipos/sagas'
import checkins from './checkins/sagas'

export default function* rootSaga() {
  yield all([user(), menu(), settings(), users(), tipos(), checkins()])
}
